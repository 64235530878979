<template>
    <apexchart
        id="radar-chart"
        ref="radarchart"
        type="radar"
        height="500"
        :options="chartOptions"
        :series="chartData"
    />
</template>
<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'UserRadarChart',
    components: {
        apexchart: VueApexCharts,
    },
    props: ['dialog'],
    data () {
        return {
            chartData: [
                {
                    name: 'あなたの動機づけスタイル',
                    data: [],
                },
                {
                    name: '低履修動機',
                    data: [10.90, 15.57, 6.54, 14.09, 10.77, 8.59],
                    hidden: true,
                },
                {
                    name: '資格取得希望',
                    data: [13.83, 17.39, 10.08, 17.30, 15.19, 9.98],
                    hidden: true,
                },
                {
                    name: '高履修動機',
                    data: [16.06, 16.58, 9.30, 18.95, 13.72, 15.44],
                    hidden: true,
                },
                {
                    name: '図書館就職希望',
                    data: [18.31, 10.82, 10.95, 19.03, 14.71, 13.66],
                    hidden: true,
                },
            ],
            chartOptions: {
                grid: {
                    padding: {
                        top: -20,
                    }
                },
                colors: ['#ee3377', '#33bbee', '#bbbbbb', '#0077bb', '#009988'],
                fill: {
                    colors: ['#ee3377', 'transparent', 'transparent', 'transparent', 'transparent'],
                    opacity: 0.2,
                    type: 'solid'
                },
                chart: {
                    height: 300,
                    type: 'radar',
                    toolbar: {
                        show: true
                    },
                    dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                    }
                },
                yaxis: {
                    stepSize: 5,
                    min: 0,
                    max: 20,
                },
                xaxis: {
                    categories:  [
                        '図書館への就職希望',
                        '資格志向の考え方',
                        '知人の司書への憧れ',
                        '本・読書への愛着',
                        '司書資格への期待',
                        '出版業界への就職希望',
                    ],
                    labels: {
                        style: {
                            colors: ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000'],
                            fontSize: '14px'
                        }
                    }
                }
            },
        }
    },
    mounted () {
        this.chartData[0].data = this.orderedSums
    },
    watch: {
        orderedSums(newData) {
            // Update chart data dynamically if orderedSums change
            this.chartData[0].data = newData;
        }
    },
    computed: {
        ...mapGetters({
            answers: 'stateScaleItemAnswers',
            notAnswered: 'stateScaleItemNotAnswered'
        }),
        isAnswered () {
            return this.notAnswered.length === 0
        },
        orderedSums () {
            let factorAggregates = {};
            this.answers.forEach(answer => {
                if (!factorAggregates[answer.scaleItemFactorId]) {
                    factorAggregates[answer.scaleItemFactorId] = {
                        factorId: answer.scaleItemFactorId,
                        factorName: answer.scaleItemFactorName,
                        total: 0,
                    };
                }
                factorAggregates[answer.scaleItemFactorId].total += answer.answer;
            });

            const factorOrder = [1, 2, 3, 4, 5, 6];
            let orderedSums = [];
            factorOrder.forEach(factorId => {
                if (factorAggregates[factorId]) {
                    const aggregate = factorAggregates[factorId];
                    orderedSums.push(aggregate.total);
                } else {
                    orderedSums.push(null);
                }
            });
            return orderedSums;
        }
    },
}

</script>