<template>
    <div>
        <v-btn
            color="indigo accent-6"
            x-large
            elevation="0"
            class="mx-auto mb-3"
            width="250"
            :dark="isAnswered"
            :disabled="!isAnswered"
            @click="makeCSV"
        >
            <span v-if="this.lang == 'ja'">回答をダウンロード</span>
            <span v-else>Download</span>
        </v-btn>
        <p
            class="caption text--secondary"
            v-if="isAnswered"
        >
            <span v-if="lang == 'ja'">
                回答をCSV形式でダウンロードします。<br>
                回答がサーバに送信されることはありません。<br>
            </span>
            <span v-else>
                Download your responses as a CSV file.<br>
                Your data remains local and is not sent to any server.<br>
            </span>
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'UserScaleAnswerDownload',
    props: ['lang'],
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters({
            answers: 'stateScaleItemAnswers',
            notAnswered: 'stateScaleItemNotAnswered'
        }),
        isAnswered () {
            return this.notAnswered.length === 0
        }
    },
    methods: {
        makeCSV () {
            let titleKeys = []
            let choices = []
            if (this.lang == 'ja')
            {
                titleKeys = ["因子番号", "因子名", "項目番号", "項目名", "回答", "回答（テキスト）", "回答日時"]
                choices = ["まったくあてはまらない", "あまりあてはまらない", "どちらとも言えない", "少しあてはまる", "よくあてはまる"]
            } else {
                titleKeys = ["Factor ID", "Factor Name", "Item ID", "Item Text", "Answer", "Answer Text", "Date"]
                choices = ["Strongly disagree", "Somewhat disagree", "Neither agree nor disagree", "Somewhat agree", "Strongly agree"]
            }

            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const currentDate = new Date()
            const currentDateString = currentDate.toLocaleString('ja-JP', { timeZone: timezone })
            const filenameDate = currentDate.toLocaleString('sv', { timeZone: timezone }).replace(/\D/g, '')

            const refinedData = []
            refinedData.push(titleKeys)
            this.answers.forEach(answer => {
                let row = []
                row.push(answer.scaleItemFactorId)
                row.push(answer.scaleItemFactorName)
                row.push(answer.scaleItemId)
                row.push(answer.scaleItemText)
                row.push(answer.answer)
                row.push(choices[answer.answer - 1])
                row.push(currentDateString)
                refinedData.push(row)
            })

            let csvContent = ''
            refinedData.forEach(row => {
                csvContent += row.join(',') + '\n'
            })

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            const objUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.setAttribute('href', objUrl)
            link.setAttribute('download', `mslc_${filenameDate}.csv`)
            link.click()
        }
    }
}

</script>