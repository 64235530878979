<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="800"
            scrollable
            persistent
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="indigo accent-3"
                x-large
                elevation="0"
                class="mx-auto mb-3"
                width="250"
                :dark="isAnswered"
                :disabled="!isAnswered"
                v-bind="attrs"
                v-on="on"
            >
                <span v-if="lang == 'ja'">動機づけスタイルを確認</span>
                <span v-else>Download</span>
            </v-btn>
            <!-- <p
                class="caption text--secondary"
                v-if="isAnswered"
            >
                <span v-if="lang == 'ja'">
                    回答がサーバに送信されることはありません。<br>
                </span>
                <span v-else>
                    Your data remains local and is not sent to any server.<br>
                </span>
            </p> -->
        </template>

        <v-card>
            <v-card-title class="pt-5 pb-5">
                <h2 class="text-h5">
                    <span v-if="this.lang == 'ja'">あなたの動機づけスタイル</span>
                </h2>
            </v-card-title>
            <v-card-text class="body-1 text-center">
                <p>
                    自分の動機づけスタイルを、いくつかの典型的なグループと見比べてみましょう。<br>
                    あなたはどのグループに一番近いでしょうか。
                </p>
                <div>
                    <UserApexRadarChart :dialog='dialog'/>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserApexRadarChart from '@/components/UserApexRadarChart'

export default {
    name: 'UserScaleAnswerResult',
    components: {
        UserApexRadarChart,
    },
    props: ['lang'],
    data () {
        return {
            dialog: false,
        }
    },
    computed: {
        ...mapGetters({
            answers: 'stateScaleItemAnswers',
            notAnswered: 'stateScaleItemNotAnswered'
        }),
        isAnswered () {
            return this.notAnswered.length === 0
        }
    }
}

</script>